<template>
  <div>
    <div class="all-height d-flex justify-center">
  <div class="all-width">
    <v-card v-if="typeOfUser === 'Free'">
      <v-card-text>
        <br>
        <br>
        <br>
        <div class="text-h5 text-center font-weight-bold" align-center> This feature is available on paid version </div>
        <br>
        <br>
      </v-card-text>
    </v-card>
    <v-card class="" loader-height="2" :loading="loading" :disabled="loading" v-else>
      <v-card-text>
        <div class="d-flex align-center mb-2">
          <div class="mr-2" v-if="metadata._id">
            <v-chip v-if="stage === 3" small label class="white--text" color="btnColor">Closed</v-chip>
            <v-chip v-else-if="stage === 1" small label class="white--text" color="btnColor">Pending for review</v-chip>
            <v-chip v-else-if="stage === 0" small label  class="white--text" color="btnColor">Open</v-chip>
          </div>
          <v-btn class="shadow-off" small icon @click="refreshData()">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </div>
        <div class="d-flex align-center mb-2" v-if="metadata._id">
          <div>
            <div class="d-flex align-center">
              <div class="subtitle-1 font-weight-bold mr-2">{{ metadata.description }}</div>
            </div>
            <div>
              <span class="mr-2">
                <v-icon class="mr-1">mdi-calendar-range</v-icon> Period:
              </span>
              <span class="mr-1">{{ $nova.formatDate(metadata.from) }}</span>
              <span class="mr-1">- {{ $nova.formatDate(metadata.to) }}</span>
            </div>
          </div>
          <v-spacer></v-spacer>
        </div>
        <div class="d-flex align-center mb-4 mt-4">
          <v-btn small color="btnColor" class="white--text" @click="addAnalysisbox()">
            <v-icon class="mr-1">mdi-plus</v-icon>
            <span>Generate Analysis</span>
          </v-btn> &nbsp;&nbsp;&nbsp;
           <v-btn small color="btnColor" class="white--text" @click="importAnalysisBox()">
            <v-icon class="mr-1">mdi-plus</v-icon>
            <span>Import Analysis</span>
          </v-btn>
        </div>
        <v-card-text class="pt-0 pb-0" v-if="formatedData.length > 0">
          
          <lb-datatablenew :headers="headers" :items="formatedData" :enableslot="['description','tableone','tabletwo','filecount','action']" :loading="loading" :bgColor="true">
            <template v-slot:description="{ item }">
              {{item.description }}
            </template>
            <template v-slot:tableone="{ item }">
              {{ getFileName(item.files[0]) }}
            </template>
            <template v-slot:tabletwo="{ item }">
              {{ getFileName(item.files[1]) }}
            </template>
             <template v-slot:filecount="{ item }">
             {{item.filecount}}
            </template>
            <template v-slot:action="{ item }">
              <div class="d-flex">
                <v-tooltip bottom content-class="tooltip-bottom">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on" @click.stop="editAnalysis(item)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </div>
            </template>
          </lb-datatablenew>
        </v-card-text>
        <div v-else class="text-center grey--text title pa-6"> No data available </div>
      </v-card-text>
    </v-card>
  </div>
</div>


<lb-dailogboxnew v-model="dialogAnalysis" width="600" :heading="analysis._id ? 'Update analysis' : 'Generate new analysis'" :loading="dialogAnalysisloading">
      <template v-slot:body>
        <div>
          <div class="d-flex align-center justify-center mt-2">
            <v-row>
              <v-col cols="12" class="my-0 py-0">
                <lb-string
                  label="Name of the Analysis*"
                  v-model="analysis.name"
                  :error="analysiserror.name"
                />
              </v-col>
               <v-col cols="12" class="my-0 py-0">
                <lb-string
                  label="Analysis Details"
                  v-model="analysis.description"
                />
              </v-col>
               <v-col cols="12" class="my-0 py-0">
                 <lb-dropdown class="flux-grow-1 mr-1" label="Type of analysis*"  :items="analysisTypeItem"  v-model="analysis.typeofanalysis"  itemtext="name" itemvalue="_id" :error="analysiserror.typeofanalysis"/>
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <lb-dropdown class="flux-grow-1 mr-1" label="File upload Options*"  :items="fileTypeItem"  v-model="analysis.filecount"  itemtext="name" itemvalue="_id" :error="analysiserror.filecount"/>
              </v-col>
              <v-col cols="6" class="my-0 py-0">
                <lb-dropdown class="flux-grow-1 mr-1" label="Select Table 1*" :items="getfiledata"  itemtext="name" itemvalue="_id" v-model="analysis.fileone" :error="analysiserror.fileone"/>
              </v-col>
              <v-col cols="6" class="my-0 py-0">
               <lb-dropdown class="flux-grow-1 mr-1" label="Select Table 2*" :items="getfiledata"   itemtext="name" itemvalue="_id" v-model="analysis.filetwo" :error="analysiserror.filetwo"/>
              </v-col>
              <v-col cols="6" class="my-0 py-0 ">
                  <lb-date v-model="analysis.startdate" label="Period From*" :error="analysiserror.startdate" :max="analysis.enddate" />
                </v-col>
                <v-col cols="6" class="my-0 py-0 ">
                  <lb-date v-model="analysis.enddate" label="Period To*" :error="analysiserror.to"  :max="analysis.enddate" :readonly="true" :disabled="true"/>
                </v-col>
            </v-row>
          </div> 
        </div>
      </template>
      <template v-slot:actions>
        <span class="caption">*Required</span>
        <v-spacer></v-spacer>
        <v-btn small color="btnColor" class="white--text" @click="updateAnalysis(analysis._id)" v-if="analysis._id">
          <span>Update</span>
        </v-btn>
        <v-btn small color="btnColor" class="white--text" @click="addAnalysis()" v-else>
          <span>Save</span>
        </v-btn>
      </template>
</lb-dailogboxnew>
<lb-dailogboxnew v-model="dialogImport" width="600" heading="Reuse Previous Analysis" :loading="dialogImportloading">
      <template v-slot:body>
        <div>
          <div class="d-flex align-center justify-center">
            <v-row>
              <v-col cols="12" class="my-0 py-0">
                <lb-dropdown class="flux-grow-1 mr-1" label="Select close cycle*"  :items="closecycleItem"  v-model="importobject.pid"   itemtext="description" itemvalue="_id" :error="importobjecterror.pid"/>
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <lb-dropdown class="flux-grow-1 mr-1" label="Select analysis*"  :items="selectanalysis"  itemtext="name" itemvalue="_id" v-model="importobject.tmaid"  :error="importobjecterror.tmaid" v-if="importobject && importobject.pid && importobject.pid.length !== 0"/>
              </v-col>
              <v-col cols="12" class="my-0 py-0">
               <lb-dropdown class="flux-grow-1 mr-1" label="Import type*"  :items="importTypeItem"  v-model="importobject.importwithdata" :error="importobjecterror.importwithdata" itemtext="name" itemvalue="_id"/>
              </v-col>
                <v-col cols="12" class="my-0 py-0">
                  <lb-date v-model="importobject.enddate" label="Period To*" :error="importobjecterror.enddate"  :max="importobject.enddate"/>
                  <div class="text--secondary">Import Cycle Existing Date</div>
                </v-col>
            </v-row>
          </div>
        </div>
      </template>
      <template v-slot:actions>
        <span class="caption">*Required</span>
        <v-spacer></v-spacer>
        <v-btn small color="btnColor" class="white--text" @click="importAnalysis()">
          <span>Import</span>
        </v-btn>
        <v-btn small color="btnColor" class="white--text" @click="dialogImport=false">
          <span>Cancel</span>
        </v-btn>
      </template>
</lb-dailogboxnew>

  </div>
</template>

<script>

export default {
	data() {
		return {
      botid: "6474e49c00b1f12f6a2a8065",
			typeOfUser: 'Paid',
			metadata: {},
			stage: null,
			loading: false,
			headers: [{
					value: "indexno",
					text: "#",
					sortable: false,
					filterable: false,
				},
				{
					value: "name",
					text: "Name of the Analysis",
					sortable: true,
					filterable: true,
					lock: true,
				},
        {
					value: "typeofanalysis",
					text: "Analysis Type",
					sortable: true,
					filterable: true,
					lock: true,
				},
				{
					value: "description",
					text: "Analysis Details",
					sortable: true,
					filterable: true,
					lock: true,
				},
				{
					value: "tableone",
					text: "Table 1",
				},
				{
					value: "tabletwo",
					text: "Table 2",
				},
        {
					value: "filecount",
					text: "File Conut",
				},
				{
					value: "action",
					text: "Action",
				},
			],
			totalanalysisList: [],
			getfiledata: [],
      fileTypeItem:[ 
        { name:'Single File',_id:'One File' },
        { name:'Two Files',_id:'Two File' },
      ],
			formatedData: [],
      dialogAnalysis:false,
      dialogAnalysisloading:false,
      analysis:{},
      importTypeItem:[
        {name:'Reuse configuration settings',_id:'false'},
        {name:'Reuse configuration settings and data',_id:'true'}
        ],
      importobject:{},
      importobjecterror:{},
      closecycleItem:[],
      dialogImport:false,
      dialogImportloading:false,
      importFile:null,
      selectanalysis:[],
      processtype:'',
      analysisTypeItem:[
        {_id:'Intercompany Reconciliation',name:"Intercompany Reconciliation"},
        {_id:'Accounts receivable Reconciliation',name:"Accounts receivable Reconciliation"},
        {_id:'Accounts payable Reconciliation',name:"Accounts payable Reconciliation"},
        {_id:'Bank Statement Reconciliation',name:"Bank Statement Reconciliation"},
        {_id:'Sales register and Books reconciliation',name:"Sales register and Books reconciliation"},
        {_id:'Others',name:"Others"}
      ],
      analysiserror:{},
      closecycleend:{},
		}
	},
	created() {
		this.refreshData();
	},
	activated() {
		this.refreshData();
	},
	methods: {
		init() {
			if (this.$store.state.subscriptions.financialclose.plan_detail.name) {
				this.typeOfUser = this.$store.state.subscriptions.financialclose.plan_detail.name || "Paid";
			}
		},
		refreshData() {
			this.init();
			this.id = this.$route.params.id || "";
			this.getData();
		},
		async getData() {
			this.loading = true;
			try {
				let dt = await this.axios.post("/v2/financialclose/analysis/getbotdetails/" + this.id);
				if (dt.data.status === "success" && dt.data.data.length > 0) {
					let data = dt.data.data[0] || {};
					this.metadata = data.metadata || {};
					this.stage = this.metadata.stage;
					let dt1 = await this.axios.post("/v2/conserve/fileconfig/get");
					if (dt1.data.status === "success") {
						this.totalfileConfig = dt1.data.data || [];
						let ele = await this.axios.post(`/v2/financialclose/analysis/getTransactionMatchingAnalysis/${this.id}`);
						if (ele.data.status === "success") {
							this.totalanalysisList = ele.data.data;
							this.formateDatafun();
							let fileMasterData = await this.axios.post("/v2/conserve/filemaster/get/"+ this.botid);
							if (fileMasterData.data.status === "success") {
								this.getfiledata = fileMasterData.data.data;
							} 
              const projectResponse = await this.axios.post("/v2/financialclose/project/get");
              if (projectResponse.data.status === "success") {
                this.closecycleItem = projectResponse.data.data;
                //console.log(this.closecycleItem, "closecycleItem");
              }
              else {
								throw new Error("Failed to fetch file master data");
							}
						} else {
							throw new Error("Failed to fetch transaction matching analysis");
						}
					} else {
						throw new Error("Failed to fetch file config");
					}
				} else {
					throw new Error("Failed to fetch bot details or no data available");
				}
			} catch (err) {
				this.$store.commit("sbError", err.message || err || "Unknown error!");
			} finally {
				this.loading = false;
			}
		},
		formateDatafun() {
      //console.log(this.totalanalysisList,"totalanalysisList");
			let ObjectTemp = {};
			let ArrayTemp = [];
			this.totalanalysisList.forEach((i, k) => {
				ObjectTemp = {
					_id: i._id,
					indexno: k + 1,
					name: i.name,
					description: i.description,
					files: i.files,
          filecount:i.filecount,
          startdate:i.startdate,
          enddate:i.enddate,
          to_process:i.to_process,
          created_at:i.created_at,
          typeofanalysis:i.typeofanalysis,
					__click: "/financialclose/automationtransactionmatching/" + this.id + "/c/" + i._id
				}
				ArrayTemp.push(ObjectTemp);
			});
			this.formatedData = ArrayTemp;
		},
		getFileName(id) {
			let files = this.getfiledata.find((x) => x._id === id);
			return (files || {}).name;
		},
    getclosecycleName(id){
      let cycle = this.closecycleItem.find((x) => x._id === id);
      return (cycle || {}).description;
    },
    closeCycledetilis(id) {
      console.log(id);
      this.dialogImportloading = true;
			this.axios.post("/v2/financialclose/analysis/getbotdetails/" + id)
      .then(dt => {
				if (dt.data.status === 'success') {
					this.closecycleend = dt.data.data[0].metadata;
          this.importobject.enddate = this.closecycleend.to
				} else {
					throw new Error(dt.data.message || "Not added")
				}
			}).catch(err => {
				this.$store.commit("sbError", err.message || err || "Unknown error!");
				console.log(err);
			}).finally(() => {
				this.dialogImportloading = false;
			})
    },
    addAnalysisbox(){
      this.dialogAnalysis = true;
      this.analysis = {
        startdate:this.metadata.from,
        enddate:this.metadata.to,
        filecount:'Two File'
      };
      this.analysiserror = {};
    },
    analysisValition(){
      this.analysiserror = {};
			if (!this.analysis.name) {
				this.$set(this.analysiserror, 'name', "Tag cannot be blank.");
			}
      if (this.analysis.filecount === null || this.analysis.filecount === undefined  || this.analysis.filecount.length === 0) {
				this.$set(this.analysiserror, 'filecount', "File Count cannot be blank.");
			}
      if (this.analysis.typeofanalysis === null || this.analysis.typeofanalysis === undefined  || this.analysis.typeofanalysis.length === 0) {
				this.$set(this.analysiserror, 'typeofanalysis', "Type of Analysis cannot be blank.");
			}
      
      if (this.analysis.fileone === null || this.analysis.fileone === undefined  || this.analysis.fileone.length === 0) {
				this.$set(this.analysiserror, 'fileone', "Select Table 1 cannot be blank.");
			}
      if (this.analysis.filetwo ===  null || this.analysis.filetwo === undefined  || this.analysis.filetwo.length === 0) {
				this.$set(this.analysiserror, 'filetwo', "Select Table 2 cannot be blank.");
			}
      if (this.analysis.startdate === undefined  || this.analysis.startdate.length === 0) {
				this.$set(this.analysiserror, 'startdate', "Start date cannot be blank.");
			}
       if (this.analysis.enddate === undefined  || this.analysis.enddate.length === 0) {
				this.$set(this.analysiserror, 'enddate', "End date cannot be blank.");
			}
      console.log(this.analysiserror,"analysiserror");
      
      return Object.keys(this.analysiserror).length === 0;
    },
    addAnalysis() {
      console.log(this.analysis);
      // this.dialogAnalysisloading = true;
      if (this.analysisValition()) {
      if (this.analysis.fileone === this.analysis.filetwo) {
			return this.$set(this.analysiserror, 'filetwo', "Cannot be same table.");
			}

      this.dialogAnalysisloading = true;
      if (this.analysis.filecount === 'One File' || this.analysis.filecount === 'More') {
        this.processtype = true;
      } else {
        this.processtype = false;
      }
      const data = {
        name: this.analysis.name,
        files: [this.analysis.fileone, this.analysis.filetwo],
        filecount: this.analysis.filecount,
        description:this.analysis.description,
        fileprocesstype: this.processtype,
        startdate: this.analysis.startdate,
        enddate: this.analysis.enddate,
        typeofanalysis:this.analysis.typeofanalysis,
        pid: this.id,
        bid: this.botid,
      };
      console.log(data, "data");
      this.axios.post("/v2/financialclose/analysis/addTransactionMatchingAnalysis", { data })
        .then((ele) => {
          if (ele.data.status === "success") {
            this.dialogAnalysis = false;
            this.$store.commit("sbSuccess", "Transaction Matching Added Successfully");
            this.getData();
          } else {
            console.log(ele.data.data);
						if (ele.data.data.length > 0 && ele.data.data[0].index0)
							this.analysiserror = ele.data.data[0].index0;
						else throw new Error(ele.data.message);
					}
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || "Unknown error!");
          console.error(err);
        })
        .finally(() => {
          this.dialogAnalysisloading = false;
        });
      }
    },
    editAnalysis(item){
      console.log(item,"item");
      this.analysis = { ...item };
      this.analysis.fileone = item.files[0];
      this.analysis.filetwo = item.files[1];
      this.analysiserror = {};
      this.dialogAnalysis = true;
     
    },
     updateAnalysis(id) {
      console.log(this.analysis);
      console.log(this.analysisValition());
      
       if (this.analysisValition()) {
      if (this.analysis.fileone === this.analysis.filetwo) {
			return this.$set(this.analysiserror, 'filetwo', "Cannot be same table.");
			}
        this.dialogAnalysisloading = true;
      if (this.analysis.filecount === 'One File' || this.analysis.filecount === 'More') {
        this.processtype = true;
      } else {
        this.processtype = false;
      }
      const data = {
        name: this.analysis.name,
        files: [this.analysis.fileone, this.analysis.filetwo],
        filecount: this.analysis.filecount,
        description:this.analysis.description,
        fileprocesstype: this.processtype,
        startdate: this.analysis.startdate,
        enddate: this.analysis.enddate,
        typeofanalysis:this.analysis.typeofanalysis,
        pid: this.id,
        bid: this.botid,
      };
      console.log(data, "data");
      this.axios.post("/v2/financialclose/analysis/updateTransactionMatchingAnalysis/"+id, { data: data})
        .then((ele) => {
          if (ele.data.status === "success") {
            this.dialogAnalysis = false;
            this.$store.commit("sbSuccess", "Transaction Matching Added Successfully");
            this.getData();
          } else {
            // this.analysiserror = data[0].index0;
            throw new Error(ele.data.message || "Error Adding Transaction Matching");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || "Unknown error!");
          console.error(err);
        })
        .finally(() => {
          this.dialogAnalysisloading = false;
        });
       }
    },
    importAnalysisBox(){
      this.importobject = {
				pid: [],
				importwithdata: [],
				tmaid: [],
			}
      this.importobjecterror = {};
      this.dialogImport = true;
    },
    valitionImportAnalsis(){
      this.importobjecterror = {};
      console.log(this.importobject);
      if (this.importobject.pid === null || this.importobject.pid.length === 0) {
				this.$set(this.importobjecterror, 'pid', "close cycle cannot be blank.");
			}
      if ( this.importobject.importwithdata === null || this.importobject.importwithdata.length === 0) {
				this.$set(this.importobjecterror, 'importwithdata', "import type cannot be blank.");
			}
      if (this.importobject.tmaid === undefined || this.importobject.tmaid === null || this.importobject.tmaid.length === 0) {
				this.$set(this.importobjecterror, 'tmaid', "analysis cannot be blank.");
			}
      if (this.importobject.enddate === undefined || this.importobject.enddate === null || this.importobject.tmaid.enddate === 0) {
				this.$set(this.importobjecterror, 'enddate', "to date cannot be blank.");
			}
			return Object.keys(this.importobjecterror).length === 0;
    },
    importAnalysis(){
      if (this.valitionImportAnalsis()) {
      let data = { cpid: this.id, pid: this.importobject.pid,bid: this.botid,  tmaid:this.importobject.tmaid, importwithdata:this.importobject.importwithdata, enddate:this.importobject.enddate }
      this.axios
        .post("/v2/financialclose/analysis/importTransactionMatchingAnalysis",{data})
        .then((dt) => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "Analysis Imported Successfully");
             this.dialogImport = false;
             this.getData();
          } else {
            this.importobjecterror = {};
            if (dt.data.data.length > 0) {
              if (dt.data.data[0].index0) this.importobjecterror = dt.data.data[0].index0;
            }
            throw new Error(dt.data.message || "Error Adding Import Analysis");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.dialogImportloading = false;
        });
      }
    }
	},
 watch: {
  "importobject.pid": function (newPid) {
    if (newPid && typeof newPid === 'string' && newPid.length > 0) {
      this.dialogImportloading = true;
      this.axios
        .post("/v2/financialclose/analysis/getTransactionMatchingAnalysis/" + newPid)
        .then((response) => {
          if (response.data.status === "success") {
            this.selectanalysis = response.data.data;
            this.closeCycledetilis(newPid)
          } else {
            throw new Error(response.data.message || "Error Get Analysis");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || "Unknown error!");
          console.error(err);
        })
        .finally(() => {
          this.dialogImportloading = false;
        });
    } else {
      this.selectanalysis = []; // Optionally clear the analysis list if pid is invalid or undefined
    }
  }
}
};
</script>

<style>
</style>